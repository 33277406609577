import { useEffect, useState } from 'react';

import { PARTNERSHIP_DATA } from '../../main-page.data';
import PartnershipSwiperItem from './partnership-swiper-item/partnership-swiper-item';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocale } from 'next-intl';
import { Navigation } from 'swiper/modules';
import type { SwiperClass } from 'swiper/react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useSwiperNavigation } from 'hooks/use-swiper-navigation';
import { useTextDirection } from 'hooks/use-text-direction';

import IconArrowRight from 'icons/common/icon-arrow-right.svg';

import styles from './partnership-swiper.module.scss';

import 'swiper/css';

const PartnershipSwiper = () => {
  const locale = useLocale();
  const isDesktopL = useMediaQuery('(max-width:1899px)');
  const showSlideBtn = useMediaQuery('(max-width:1390px)');

  const { swiperRef, onSwiper, nextBtnRef, prevBtnRef } = useSwiperNavigation();
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const textDirection = useTextDirection();
  const emulateOutlideClick = () => document.body.click();

  useEffect(() => {
    if (!isMounted) setIsMounted(true);
  }, [isMounted]);

  useEffect(() => {
    const swiper = swiperRef?.current as SwiperClass;

    swiper?.slideTo(0);

    if (isDesktopL) {
      swiper?.enable();
    } else {
      swiper?.disable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktopL]);

  useEffect(() => {
    swiperRef.current?.update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  return (
    <div className={styles.container}>
      <Swiper
        className={styles.swiper}
        dir={textDirection}
        grabCursor={false}
        modules={[Navigation]}
        slidesPerView='auto'
        breakpoints={{
          0: { spaceBetween: 16 },
          768: { spaceBetween: 24 },
        }}
        onBeforeInit={onSwiper}
        onResize={onSwiper}
        onSliderMove={emulateOutlideClick}
      >
        {PARTNERSHIP_DATA?.map((slide) => (
          <SwiperSlide key={slide.title} className={styles.swiper__slide}>
            <PartnershipSwiperItem slide={slide} />
          </SwiperSlide>
        ))}
      </Swiper>
      {showSlideBtn ? (
        <div className={styles.buttons}>
          <button key={`${locale}-left`} ref={prevBtnRef}>
            <IconArrowRight />
          </button>
          <button key={`${locale}-right`} ref={nextBtnRef}>
            <IconArrowRight />
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default PartnershipSwiper;
