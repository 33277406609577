'use client';

import React from 'react';

import clsx from 'clsx';
import { useLocale } from 'next-intl';

import AccessBlock from 'components/main-page/access-block/access-block';
import JoinCryptomusBlock from 'components/main-page/join-cryptomus-block/join-cryptomus-block';
import MediaBlock from 'components/main-page/media-block/media-block';
import OurProducts from 'components/main-page/our-products/our-products';
import PartnershipProgrammes from 'components/main-page/partnership-programmes/partnership-programmes';
import SignUp from 'components/main-page/sign-up/sign-up';
import TradingBanner from 'components/main-page/trading-banner/trading-banner';

import styles from './main-page-index.module.scss';

const MainPageIndex = () => {
  const locale = useLocale();

  return (
    <main className={styles.main}>
      <section className={clsx(styles.container, styles.sign_up__background)}>
        <SignUp />
      </section>
      <section className={styles.container}>
        <TradingBanner locale={locale} />
      </section>
      <section className={styles.container}>
        <OurProducts />
      </section>
      <section className={styles.container}>
        <MediaBlock />
      </section>
      <section className={styles.partners}>
        <div className={styles.container}>
          <PartnershipProgrammes />
        </div>
      </section>
      <section className={styles.access_block}>
        <div className={styles.container}>
          <AccessBlock />
        </div>
      </section>
      <section className={styles.join_cryptomus_block}>
        <div className={styles.container}>
          <JoinCryptomusBlock locale={locale} />
        </div>
      </section>
    </main>
  );
};

export default MainPageIndex;
