import React from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';

import { useGetOurProductsItems } from 'components/main-page/our-products/lib/use-get-our-products-items';
import OurProductsItem from 'components/main-page/our-products/our-products-item/our-products-item';

import styles from './our-products.module.scss';

const OurProducts: React.FC = () => {
  const OUR_PRODUCTS = useGetOurProductsItems();
  const t = useTranslations('indexPage');
  const isLaptopSizes = useMediaQuery('(max-width:1360px)');

  const renderProductsItems = (row: number, condition?: boolean) =>
    OUR_PRODUCTS?.filter((item) =>
      condition ? item.row > 1 : item.row === row
    ).map((item) => <OurProductsItem key={item.description} {...item} />);

  return (
    <div className={styles.products}>
      <h1>{t('Our products')}</h1>

      <div className={clsx(styles.products__row, styles.products__first_row)}>
        {renderProductsItems(1)}
      </div>

      {isLaptopSizes ? (
        <div className={clsx(styles.products__row, styles.products__area)}>
          {renderProductsItems(1, true)}
        </div>
      ) : (
        <>
          <div
            className={clsx(styles.products__row, styles.products__second_row)}
          >
            {renderProductsItems(2)}
          </div>

          <div
            className={clsx(styles.products__row, styles.products__third_row)}
          >
            {renderProductsItems(3)}
          </div>

          <div
            className={clsx(styles.products__row, styles.products__fourth_row)}
          >
            {renderProductsItems(4)}
          </div>
        </>
      )}
    </div>
  );
};

export default OurProducts;
