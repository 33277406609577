import React from 'react';
import Image from 'next/image';

import { useTranslations } from 'next-intl';

import type { IOurProduct } from 'components/main-page/main-page.data';

import NewStatusIcon from 'icons/main/new-status-icon.svg';
import BuyCryptoIcon from 'icons/main/products/buy-crypto-icon.svg';
import ConvertIcon from 'icons/main/products/convert-icon.svg';
import CryptoProcessingIcon from 'icons/main/products/crypto-processing-icon.svg';
import DirectoryIcon from 'icons/main/products/directory-icon.svg';
import ExplorerIcon from 'icons/main/products/explorer-icon.svg';
import WhiteLabelIcon from 'icons/main/products/label-icon.svg';
import MassPaymentsIcon from 'icons/main/products/mass-payments-icon.svg';
import P2PExchangeIcon from 'icons/main/products/p2p-icon.svg';
import StakingIcon from 'icons/main/products/staking-icon.svg';
import WalletIcon from 'icons/main/products/wallet-icon.svg';

type TUseGetOurProductsItemsProps = () => IOurProduct[];

export const useGetOurProductsItems: TUseGetOurProductsItemsProps = () => {
  const t = useTranslations('indexPage');

  return [
    {
      badgeProps: { icon: <NewStatusIcon />, text: t('NEW'), variant: 'green' },
      description: 'Simple and convenient crypto trading hub',
      icon: (
        <Image
          alt='render-products-article-spot-trading'
          height={152}
          src='/img/main/render-products-article-spot-trading.png'
          width={244}
        />
      ),
      link: '/exchange/trading',
      mobileBackground: '/img/main/spot-trading-mobile-background.png',
      mobileBackgroundHeight: '122',
      mobileBackgroundWidth: '197',
      row: 1,
      title: 'Spot trading',
    },
    {
      description:
        'Use our advanced tools to accept crypto payments for your business',
      icon: (
        <Image
          alt='Payment Gateway'
          height={148}
          src='/img/main/render-products-article-wallet.png'
          width={269}
        />
      ),
      link: '/gateway',
      mobileBackground: '/img/main/payment-gateway-mobile-background.png',
      mobileBackgroundHeight: '148',
      mobileBackgroundWidth: '269',
      row: 1,
      title: 'Payment Gateway',
    },
    {
      description:
        'Store and swap funds in a secure wallet with multi-level security',
      link: '/wallet',
      mobile_icon: <WalletIcon />,
      row: 2,
      title: 'Wallet',
    },
    {
      description:
        'Integrate Cryptomus into your project and start getting paid in crypto',
      link: '/processing',
      mobile_icon: <CryptoProcessingIcon />,
      row: 2,
      title: 'Crypto processing',
      type: 'Business',
    },
    // TODO
    // {
    //     row: 2,
    //     title: 'Market Cap',
    //     description: 'Check the precise exchange rate for each coin listed',
    //     link: '/',
    //     type: 'Business',
    //     status: 'Other',
    //     mobile_icon: <MarketCapIcon/>
    // },

    // TODO
    // {
    //     row: 3,
    //     title: 'Widgets',
    //     description: 'Integrate cryptocurrency payments seamlessly',
    //     link: '/',
    //     type: 'Business',
    //     mobile_icon: <WidgetsIcon/>
    // },
    {
      description: 'Bulk payments to your customers with an API',
      link: '/mass-payout',
      mobile_icon: <MassPaymentsIcon />,
      row: 3,
      title: 'Mass Payments',
      type: 'Business',
    },
    {
      description: 'Swap cryptocurrencies instantly for other assets',
      link: '/convert',
      mobile_icon: <ConvertIcon />,
      row: 3,
      title: 'Convert',
    },
    {
      badgeProps: { icon: <NewStatusIcon />, text: '20%', variant: 'red' },
      description: 'Earn up to 20% APR on passive income',
      link: '/staking',
      mobile_icon: <StakingIcon />,
      row: 3,
      title: 'Staking',
    },

    {
      description: 'Trade crypto directly with other users',
      link: '/p2p-exchange',
      mobile_icon: <P2PExchangeIcon />,
      row: 4,
      title: 'P2P Exchange',
    },
    {
      description: 'Access crypto  worldwide and pay with any payment method',
      link: '/buy',
      mobile_icon: <BuyCryptoIcon />,
      row: 4,
      title: 'Buy Crypto',
    },
    {
      badgeProps: { text: t('Other'), variant: 'default' },
      description: 'The easiest way to track your transactions',
      link: '/explorer',
      mobile_icon: <ExplorerIcon />,
      row: 4,
      title: 'Explorer',
    },
    {
      badgeProps: { text: t('Other'), variant: 'default' },
      description: 'Place your business here and attract more customers',
      link: 'https://directory.cryptomus.com/',
      link_type: 'external',
      mobile_icon: <DirectoryIcon />,
      row: 4,
      title: 'Directory',
    },
    {
      description: 'Integrate cryptocurrency payments seamlessly',
      link: '/white_label',
      mobile_icon: <WhiteLabelIcon />,
      row: 4,
      title: 'White Label',
      type: 'Business',
    },
  ];
};
