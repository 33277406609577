import React from 'react';

import cn from 'clsx';
import { useMediaQuery } from 'cryptomus-aurora-kit';
import type { ReactNode } from 'react';

import styles from './trading-badge.module.scss';

export type TTradingBadgeVariants = 'green' | 'red' | 'default';

export interface ITradingBadgeProps {
  icon?: ReactNode;
  text?: string;
  variant?: TTradingBadgeVariants;
}

const TradingBadge: React.FC<ITradingBadgeProps> = ({
  variant = 'green',
  text,
  icon,
}) => {
  const isTablet = useMediaQuery('tablet');

  return (
    <div className={cn(styles.badge, styles[variant])}>
      {icon || null}

      {!isTablet && <span className={styles.text}>{text}</span>}
    </div>
  );
};

export default TradingBadge;
